export const fileSearchMimeTypes = [
  { mime_type: 'text/x-c', format: '.c' },
  { mime_type: 'text/x-csharp', format: '.cs' },
  { mime_type: 'text/x-c++', format: '.cpp' },
  { mime_type: 'application/msword', format: '.doc' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml. document', format: '.docx' },
  { mime_type: 'text/html', format: '.html' },
  { mime_type: 'text/x-java', format: '.java' },
  { mime_type: 'application/json', format: '.json' },
  { mime_type: 'application/x-ndjason', format: '.json' },
  { mime_type: 'ext/markdown', format: '.md' },
  { mime_type: 'application/pdf', format: '.pdf' },
  { mime_type: 'text/x-php', format: '.php' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.presentationml. presentation', format: '.pptx' },
  { mime_type: 'text/x-python', format: '.py' },
  { mime_type: 'text/x-script.python', format: '.py' },
  { mime_type: 'text/x-ruby', format: '.rb' },
  { mime_type: 'text/x-tex', format: '.tex' },
  { mime_type: 'text/plain', format: '.txt' },
  { mime_type: 'text/css', format: '.css' },
  { mime_type: 'text/javascript', format: '.js' },
  { mime_type: 'application/x-sh', format: '.sh' },
  { mime_type: 'application/typescript', format: '.ts' },
];

export const codeInterpreterMimeTypes = [
  { mime_type: 'text/x-c', format: '.c' },
  { mime_type: 'text/x-csharp', format: '.cs' },
  { mime_type: 'text/x-c++', format: '.cpp' },
  { mime_type: 'application/msword', format: '.doc' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.wordprocessingml. document', format: '.docx' },
  { mime_type: 'text/html', format: '.html' },
  { mime_type: 'text/x-java', format: '.java' },
  { mime_type: 'application/json', format: '.json' },
  { mime_type: 'application/x-ndjason', format: '.json' },
  { mime_type: 'ext/markdown', format: '.md' },
  { mime_type: 'application/pdf', format: '.pdf' },
  { mime_type: 'text/x-php', format: '.php' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.presentationml. presentation', format: '.pptx' },
  { mime_type: 'text/x-python', format: '.py' },
  { mime_type: 'text/x-script.python', format: '.py' },
  { mime_type: 'text/x-ruby', format: '.rb' },
  { mime_type: 'text/x-tex', format: '.tex' },
  { mime_type: 'text/plain', format: '.txt' },
  { mime_type: 'text/css', format: '.css' },
  { mime_type: 'text/javascript', format: '.js' },
  { mime_type: 'application/x-sh', format: '.sh' },
  { mime_type: 'application/typescript', format: '.ts' },
  { mime_type: 'application/csv' , format: '.csv' },
  { mime_type: 'image/jpeg', format: '.jpeg' },
  { mime_type:'image/gif', format: '.gif' },
  { mime_type: 'image/png', format: '.png' },
  { mime_type: 'application/x-tar', format: '.tar' },
  { mime_type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', format: '.xlsx' },
  { mime_type: 'application/xml', format: '.xml' },
  { mime_type: 'text/xml', format: '.xml' },
  { mime_type:'application/zip', format: '.zip' },
];