import {isMoreThan1LessThan7Days, isMoreThan30Days, isMoreThan7LessThan30Days, isToday} from "@/helpers/period_fiters";

export const chatPeriods = {
  today:  {
    name: 'СЕГОДНЯ',
    method: isToday,
  },
  weekAgo: {
    name: 'ПОСЛЕДНИЕ 7 ДНЕЙ',
    method: isMoreThan1LessThan7Days,
  },
  monthAgo: {
    name: 'ПОСЛЕДНИЕ 30 ДНЕЙ',
    method: isMoreThan7LessThan30Days,
  },
  before: {
    name: 'БОЛЕЕ 30 ДНЕЙ',
    method: isMoreThan30Days,
  },
};