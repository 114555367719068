export const fileTabs = {
  'gpt-4-1106-preview': {},
  'gpt-4-0613' : {},
  'gpt-4-32k-0613': {},
  'gpt-3.5-turbo-1106': {},
  'gpt-4-0125-preview': {},
  'gpt-3.5-turbo-0125': {},
  'gpt-4-vision-preview': {
    image: 'Изображение',
    image_link: 'Ссылка на изображение',
  },
  'gpt-4': {},
  'gpt-4o': {
    image: 'Изображение',
    image_link: 'Ссылка на изображение',
  },

  'gpt-4o-mini': {
    image: 'Изображение',
    image_link: 'Ссылка на изображение',
  },

  'gpt-4o_assistant': {
    image: 'Изображение',
    code_interpreter: 'Интерпретор кода',
    file_search: 'Поиск файлов',
  },
  'gpt-4_assistant': {
    code_interpreter: 'Интерпретор кода',
  },
  'gpt-4-1106-preview_assistant': {
    code_interpreter: 'Интерпретор кода',
    file_search: 'Поиск файлов',
  },
  'gpt-4-0125-preview_assistant': {
    code_interpreter: 'Интерпретор кода',
    file_search: 'Поиск файлов',
  },
  'gpt-4-turbo_assistant' :{
    code_interpreter: 'Интерпретор кода',
    file_search: 'Поиск файлов',
  },
  'gpt-4-0613_assistant': {
    code_interpreter: 'Интерпретор кода',
  },

  'whisper-1': {
    audio: 'Аудио',
    video: 'Видео',
  },
  'openai-whisper-large-v3': {
    audio: 'Аудио',
    video: 'Видео',
  },

  'dall-e-3': {},

  '5.2': {
    image: 'Изображение',
  },
  'niji': {
    image: 'Изображение',
  },
  'describe': {
    image: 'Изображение',
  },

  '3.0': {},
  'tts-1': {},
  'tts-1-hd': {},

};

export const fileTabsForChat =  {
  code_interpreter: {
    code_interpreter: 'Интерпретор кода',
  },

  file_search: {
    file_search: 'Поиск файлов',
  },
}