export const calendar_ru_localization = {
  startsWith: 'Начинается с',
  contains: 'Содержит',
  notContains: 'Не содержит',
  endsWith: 'Заканчивается на',
  equals: 'Равно',
  notEquals: 'Не равно',
  noFilter: 'Без фильтра',
  lt: 'Меньше чем',
  lte: 'Меньше или равно',
  gt: 'Больше чем',
  gte: 'Больше или равно',
  dateIs: 'Дата равна',
  dateIsNot: 'Дата не равна',
  dateBefore: 'Дата до',
  dateAfter: 'Дата после',
  clear: 'Очистить',
  apply: 'Применить',
  matchAll: 'Совпадают все',
  matchAny: 'Совпадает любое',
  addRule: 'Добавить правило',
  removeRule: 'Удалить правило',
  accept: 'Да',
  reject: 'Нет',
  choose: 'Выбрать',
  upload: 'Загрузить',
  cancel: 'Отмена',
  dayNames: ["Воскресенье", "Понедельник", "Вторник", "Среда", "Четверг", "Пятница", "Суббота"],
  dayNamesShort: ["Вск", "Пон", "Втр", "Срд", "Чтв", "Птн", "Сбт"],
  dayNamesMin: ["Вс","Пн","Вт","Ср","Чт","Пт","Сб"],
  monthNames: ["Январь","Февраль","Март","Апрель","Май","Июнь","Июль","Август","Сентябрь","Октябрь","Ноябрь","Декабрь"],
  monthNamesShort: ["Янв","Фев","Мар","Апр","Май","Июн","Июл","Авг","Сен","Окт","Ноя","Дек"],
  dateFormat: 'dd/mm/yy',
  firstDayOfWeek: 1,
  today: 'Сегодня',
  weekHeader: 'Нед',
  weak: 'Слабый',
  medium: 'Средний',
  strong: 'Сильный',
  passwordPrompt: 'Введите пароль',
  emptyFilterMessage: 'Результатов не найдено',
  emptyMessage: 'Данных нет'
};