export const imageInteractions = [
    {
        interaction: 'variation',
        value: 'strong',
        name: 'Варианты (силные изменения)',
    },
    {
        interaction: 'variation',
        value: 'subtle',
        name: 'Варианты (изменения в деталях)',
    },
    {
        interaction: 'upscale',
        value: '2x',
        name: 'Увеличить разрешение (2x)',
    },
    {
        interaction: 'upscale',
        value: '4x',
        name: 'Увеличить разрешение (4x)',
    },
    {
        interaction: 'zoom',
        value: '2x',
        name: 'Отдалить (2x)',
    },
    {
        interaction: 'zoom',
        value: '1.5x',
        name: 'Отдалить (1.5x)',
    },
    {
        interaction: 'expand',
        value: 'left',
        name: `<svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-big-left">
                  <path id="Vector" d="M18.3335 13.75H11.0002V17.0372C11.0001 17.2185 10.9464 17.3957 10.8456 17.5464C10.7449 17.6971 10.6018 17.8145 10.4343 17.8839C10.2668 17.9533 10.0825 17.9714 9.90474 17.9361C9.72695 17.9007 9.56363 17.8134 9.43544 17.6853L3.39827 11.6481C3.22642 11.4762 3.12988 11.2431 3.12988 11C3.12988 10.757 3.22642 10.5238 3.39827 10.3519L9.43544 4.31477C9.56363 4.18662 9.72695 4.09934 9.90474 4.06399C10.0825 4.02864 10.2668 4.04679 10.4343 4.11615C10.6018 4.18552 10.7449 4.30298 10.8456 4.45369C10.9464 4.6044 11.0001 4.78159 11.0002 4.96286V8.25002H18.3335C18.5766 8.25002 18.8098 8.3466 18.9817 8.51851C19.1536 8.69042 19.2502 8.92358 19.2502 9.16669V12.8334C19.2502 13.0765 19.1536 13.3096 18.9817 13.4815C18.8098 13.6534 18.5766 13.75 18.3335 13.75Z" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>`,
    },
    {
        interaction: 'expand',
        value: 'right',
        name: `<svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-big-right">
                  <path id="Vector" d="M3.66667 8.25002H11V4.96286C11 4.78159 11.0538 4.6044 11.1545 4.45369C11.2553 4.30298 11.3984 4.18552 11.5659 4.11615C11.7334 4.04679 11.9177 4.02864 12.0954 4.06399C12.2732 4.09934 12.4366 4.18662 12.5647 4.31477L18.6019 10.3519C18.7738 10.5238 18.8703 10.757 18.8703 11C18.8703 11.2431 18.7738 11.4762 18.6019 11.6481L12.5647 17.6853C12.4366 17.8134 12.2732 17.9007 12.0954 17.9361C11.9177 17.9714 11.7334 17.9533 11.5659 17.8839C11.3984 17.8145 11.2553 17.6971 11.1545 17.5464C11.0538 17.3957 11 17.2185 11 17.0372V13.75H3.66667C3.42355 13.75 3.19039 13.6534 3.01849 13.4815C2.84658 13.3096 2.75 13.0765 2.75 12.8334V9.16669C2.75 8.92358 2.84658 8.69042 3.01849 8.51851C3.19039 8.3466 3.42355 8.25002 3.66667 8.25002Z" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>`,
    },
    {
        interaction: 'expand',
        value: 'top',
        name: `<svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-big-up">
                  <path id="Vector" d="M8.25002 18.3335V11.0002H4.96286C4.78159 11.0001 4.6044 10.9464 4.45369 10.8456C4.30298 10.7449 4.18552 10.6018 4.11615 10.4343C4.04679 10.2668 4.02864 10.0825 4.06399 9.90474C4.09934 9.72695 4.18662 9.56363 4.31477 9.43544L10.3519 3.39827C10.5238 3.22642 10.757 3.12988 11 3.12988C11.2431 3.12988 11.4762 3.22642 11.6481 3.39827L17.6853 9.43544C17.8134 9.56363 17.9007 9.72695 17.9361 9.90474C17.9714 10.0825 17.9533 10.2668 17.8839 10.4343C17.8145 10.6018 17.6971 10.7449 17.5464 10.8456C17.3957 10.9464 17.2185 11.0001 17.0372 11.0002H13.75V18.3335C13.75 18.5766 13.6534 18.8098 13.4815 18.9817C13.3096 19.1536 13.0765 19.2502 12.8334 19.2502H9.16669C8.92358 19.2502 8.69042 19.1536 8.51851 18.9817C8.3466 18.8098 8.25002 18.5766 8.25002 18.3335Z" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
              </svg>`,
    },
    {
        interaction: 'expand',
        value: 'bottom',
        name: `<svg class="app-icon" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-big-down">
                    <path id="Vector" d="M13.75 3.66667V11H17.0372C17.2185 11 17.3957 11.0538 17.5464 11.1545C17.6971 11.2553 17.8145 11.3984 17.8839 11.5659C17.9533 11.7334 17.9714 11.9177 17.9361 12.0954C17.9007 12.2732 17.8134 12.4366 17.6853 12.5647L11.6481 18.6019C11.4762 18.7738 11.2431 18.8703 11 18.8703C10.757 18.8703 10.5238 18.7738 10.3519 18.6019L4.31477 12.5647C4.18662 12.4366 4.09934 12.2732 4.06399 12.0954C4.02864 11.9177 4.04679 11.7334 4.11615 11.5659C4.18552 11.3984 4.30298 11.2553 4.45369 11.1545C4.6044 11.0538 4.78159 11 4.96286 11H8.25002V3.66667C8.25002 3.42355 8.3466 3.19039 8.51851 3.01849C8.69042 2.84658 8.92358 2.75 9.16669 2.75H12.8334C13.0765 2.75 13.3096 2.84658 13.4815 3.01849C13.6534 3.19039 13.75 3.42355 13.75 3.66667Z" stroke="#30B271" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
               </svg>`,
    },
];
const numbers = ['1', '2', '3', '4'];
export const setInteractions = [
    ...numbers.map((number) => ({
        interaction: 'upscale',
        value: number,
        name: `Выбрать ${number}`
    })),
    ...numbers.map((number) => ({
        interaction: 'variation',
        value: number,
        name: `Варианты для ${number}`,
    })),
    {interaction: 'rerender', name: 'Сгенерировать заново'}
];