export const calculationParams = {
    calc_method: 'Способ калькуляции',
    calc_output_price: 'Стоимость входящего токена',
    calc_rate: 'Курс валюты',
};

export const methodToCostName = {
    token: 'Стоимость исходящего токена',
    second: 'Стоимость секунды генерации ответа',
    response: 'Стоимость одного ответа',
    symbol: 'Стоимость за 1 символ',
};

export const methodToName = {
    token: 'за токен',
    second: 'за секунду генерации',
    response: 'за ответ',
    symbol: 'за символ',
};

export const priceToUnits = {
    completion_tokens: 'шт.',
    prompt_tokens: 'шт.',
    generation_time: 'сек.',
    response_price: 'ответ',
    symbols_count: 'симв.',
}