export const optionNames = {
    quality: 'Качество изображения',
    size: 'Размер изображения',
    style: 'Стиль изображения',
    stop: 'Остановка процесса генерации',
    stylize: 'Степень артистичности',
    no: 'Исключаемые объекты',
    language: 'Язык голосового сообщения',
    prompt: 'Описание стиля транскрипции',
    negative: 'Негативный промпт',
    detail: 'Режим распознавания',
    voice: 'Голос',
    format: 'Формат файла',
    speed: 'Скорость',
    initial_prompt: 'Промпт-описание',
    is_diarization: 'Производить диаризацию',
    num_speakers: 'Количество говорящих',
    image_url: 'Ссылка на изображение',
    max_tokens: 'Максимальное число токенов в ответе',
    temperature: 'Температура',
    async_mode: 'Асинхронный режим работы',
}

export const kandinskyStyleOptions = {
    KANDINSKY: 'Кандинский',
    UHD : 'Детальное фото',
    ANIME: 'Аниме',
    DEFAULT: 'Свой стиль',
};

export const visionDetailOptions = {
  low: 'Упрощенное',
  high: 'Детализированное',
};

export const ttsVoicesOptions = {
  onyx: 'Onyx (мужкой) Топ',
  nova: 'Nova (женский) Топ',
  alloy: 'Alloy (мужской)',
  echo: 'Echo (мужкой)',
  fable: 'Fable (мужкой)',
  shimmer: 'Shimmer (женский)',
};

export const dalleStyleOptions = {
  vivid: 'Гипперреальный',
  natural: 'Естественный',
};

export const dalleQualityOptions = {
  standard: 'Стандартное',
  hd: 'Высокая детализация',
};

export const sttLanguageOptions = {
  ru: 'Русский',
  en: 'Английский',
};

export const sttDiarizationOptions = {
  'true': 'да',
}

export const midjourneyStyleOptions = {
  raw: 'Сырой стиль',
  cute: 'Милый',
  scenic: 'Живописный',
  expressive: 'Выразителный',
};

export const yandexAsyncModeOptions = {
  1: 'да',
  0: 'нет',
}

export const getOptionValueName = (ai, value) => {
  if (ai === 'kandinsky' && kandinskyStyleOptions[value]) {
    return kandinskyStyleOptions[value];
  }
  if (ai === 'chatgpt' && visionDetailOptions[value]) {
    return visionDetailOptions[value];
  }
  if (ai === 'dalle' && dalleQualityOptions[value]) {
    return dalleQualityOptions[value];
  }
  if (ai === 'dalle' && dalleStyleOptions[value]) {
    return dalleStyleOptions[value];
  }
  if ((ai === 'speech_to_text' || ai === 'offline_speech_to_text') && sttLanguageOptions[value]) {
    return sttLanguageOptions[value];
  }
  if (ai === 'offline_speech_to_text' && sttDiarizationOptions[value]) {
    return sttDiarizationOptions[value];
  }
  if (ai === 'midjourney' && midjourneyStyleOptions[value]) {
    return midjourneyStyleOptions[value];
  }
  if (ai ==='yandex_gpt' && yandexAsyncModeOptions[value] ) {
    return yandexAsyncModeOptions[value];
  }
  return value;
}
